export type CardElement =
  | "None"
  | "Thunder"
  | "Ice"
  | "Wind"
  | "Poison"
  | "Fire"
  | "Water"
  | "Earth"
  | "Holy";

export const elements: CardElement[] = [
  "None",
  "Thunder",
  "Ice",
  "Wind",
  "Poison",
  "Fire",
  "Water",
  "Earth",
  "Holy",
];

export type CardEdition = "ff8";

export const editions: CardEdition[] = ["ff8"];

export type CardKindDefinition = {
  id: number;
  up: number;
  down: number;
  left: number;
  right: number;
  name: string;
  level: number;
  rarity: number;
  element: CardElement;
  edition: CardEdition;
};

export const CardKinds: CardKindDefinition[] = [
  {
    id: 1,
    up: 1,
    down: 1,
    left: 5,
    right: 4,
    name: "Geezard",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 2,
    up: 5,
    down: 1,
    left: 3,
    right: 1,
    name: "Funguar",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 3,
    up: 1,
    down: 3,
    left: 5,
    right: 3,
    name: "Bite Bug",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 4,
    up: 6,
    down: 1,
    left: 2,
    right: 1,
    name: "Red Bat",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 5,
    up: 2,
    down: 1,
    left: 5,
    right: 3,
    name: "Blobra",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 6,
    up: 2,
    down: 4,
    left: 4,
    right: 1,
    name: "Gayla",
    level: 1,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 7,
    up: 1,
    down: 4,
    left: 1,
    right: 5,
    name: "Gesper",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 8,
    up: 3,
    down: 2,
    left: 1,
    right: 5,
    name: "Fastitocalon-F",
    level: 1,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 9,
    up: 2,
    down: 6,
    left: 1,
    right: 1,
    name: "Blood Soul",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 10,
    up: 4,
    down: 4,
    left: 3,
    right: 2,
    name: "Caterchipillar",
    level: 1,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 11,
    up: 2,
    down: 2,
    left: 6,
    right: 1,
    name: "Cockatrice",
    level: 1,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 12,
    up: 7,
    down: 3,
    left: 1,
    right: 1,
    name: "Grat",
    level: 2,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 13,
    up: 6,
    down: 2,
    left: 3,
    right: 2,
    name: "Buel",
    level: 2,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 14,
    up: 5,
    down: 3,
    left: 4,
    right: 3,
    name: "Mesmerize",
    level: 2,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 15,
    up: 6,
    down: 4,
    left: 3,
    right: 1,
    name: "Glacial Eye",
    level: 2,
    rarity: 1,
    element: elements[2],
    edition: editions[0],
  },
  {
    id: 16,
    up: 3,
    down: 5,
    left: 3,
    right: 4,
    name: "Belhelmel",
    level: 2,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 17,
    up: 5,
    down: 2,
    left: 5,
    right: 3,
    name: "Thrustaevis",
    level: 2,
    rarity: 1,
    element: elements[3],
    edition: editions[0],
  },
  {
    id: 18,
    up: 5,
    down: 3,
    left: 5,
    right: 1,
    name: "Anaconduar",
    level: 2,
    rarity: 1,
    element: elements[4],
    edition: editions[0],
  },
  {
    id: 19,
    up: 5,
    down: 5,
    left: 2,
    right: 2,
    name: "Creeps",
    level: 2,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 20,
    up: 4,
    down: 5,
    left: 2,
    right: 4,
    name: "Grendel",
    level: 2,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 21,
    up: 3,
    down: 1,
    left: 7,
    right: 2,
    name: "Jell Eye",
    level: 2,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 22,
    up: 5,
    down: 5,
    left: 3,
    right: 2,
    name: "Grand Mantis",
    level: 2,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 23,
    up: 6,
    down: 3,
    left: 2,
    right: 6,
    name: "Forbidden",
    level: 3,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 24,
    up: 6,
    down: 1,
    left: 6,
    right: 3,
    name: "Armadodo",
    level: 3,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 25,
    up: 3,
    down: 5,
    left: 5,
    right: 5,
    name: "Tri-Face",
    level: 3,
    rarity: 1,
    element: elements[4],
    edition: editions[0],
  },
  {
    id: 26,
    up: 7,
    down: 1,
    left: 3,
    right: 5,
    name: "Fastitocalon",
    level: 3,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 27,
    up: 7,
    down: 5,
    left: 3,
    right: 1,
    name: "Snow Lion",
    level: 3,
    rarity: 1,
    element: elements[2],
    edition: editions[0],
  },
  {
    id: 28,
    up: 5,
    down: 3,
    left: 3,
    right: 6,
    name: "Ochu",
    level: 3,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 29,
    up: 5,
    down: 2,
    left: 4,
    right: 6,
    name: "SAM08G",
    level: 3,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 30,
    up: 4,
    down: 7,
    left: 2,
    right: 4,
    name: "Death Claw",
    level: 3,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 31,
    up: 6,
    down: 6,
    left: 3,
    right: 2,
    name: "Cactuar",
    level: 3,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 32,
    up: 3,
    down: 4,
    left: 4,
    right: 6,
    name: "Tonberry",
    level: 3,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 33,
    up: 7,
    down: 3,
    left: 5,
    right: 2,
    name: "Abyss Worm",
    level: 3,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 34,
    up: 2,
    down: 6,
    left: 7,
    right: 3,
    name: "Turtapod",
    level: 4,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 35,
    up: 6,
    down: 4,
    left: 5,
    right: 5,
    name: "Vysage",
    level: 4,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 36,
    up: 4,
    down: 2,
    left: 7,
    right: 6,
    name: "T-Rexaur",
    level: 4,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 37,
    up: 2,
    down: 6,
    left: 3,
    right: 7,
    name: "Bomb",
    level: 4,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 38,
    up: 1,
    down: 4,
    left: 7,
    right: 6,
    name: "Blitz",
    level: 4,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 39,
    up: 7,
    down: 1,
    left: 6,
    right: 3,
    name: "Wendigo",
    level: 4,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 40,
    up: 7,
    down: 4,
    left: 4,
    right: 4,
    name: "Torama",
    level: 4,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 41,
    up: 3,
    down: 3,
    left: 6,
    right: 7,
    name: "Imp",
    level: 4,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 42,
    up: 6,
    down: 7,
    left: 3,
    right: 2,
    name: "Blue Dragon",
    level: 4,
    rarity: 1,
    element: elements[4],
    edition: editions[0],
  },
  {
    id: 43,
    up: 4,
    down: 5,
    left: 6,
    right: 5,
    name: "Adamantoise",
    level: 4,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 44,
    up: 7,
    down: 4,
    left: 3,
    right: 5,
    name: "Hexadragon",
    level: 4,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 45,
    up: 6,
    down: 6,
    left: 5,
    right: 5,
    name: "Iron Giant",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 46,
    up: 3,
    down: 5,
    left: 7,
    right: 6,
    name: "Behemoth",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 47,
    up: 7,
    down: 5,
    left: 3,
    right: 6,
    name: "Chimera",
    level: 5,
    rarity: 1,
    element: elements[6],
    edition: editions[0],
  },
  {
    id: 48,
    up: 3,
    down: 2,
    left: 1,
    right: 10,
    name: "Pupu",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 49,
    up: 6,
    down: 6,
    left: 7,
    right: 2,
    name: "Elastoid",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 50,
    up: 5,
    down: 7,
    left: 4,
    right: 5,
    name: "GIM47N",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 51,
    up: 7,
    down: 4,
    left: 2,
    right: 7,
    name: "Malboro",
    level: 5,
    rarity: 1,
    element: elements[4],
    edition: editions[0],
  },
  {
    id: 52,
    up: 7,
    down: 7,
    left: 4,
    right: 2,
    name: "Ruby Dragon",
    level: 5,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 53,
    up: 5,
    down: 7,
    left: 6,
    right: 3,
    name: "Elnoyle",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 54,
    up: 4,
    down: 7,
    left: 4,
    right: 6,
    name: "Tonberry King",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 55,
    up: 6,
    down: 2,
    left: 7,
    right: 6,
    name: "Biggs and Wedge",
    level: 5,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 56,
    up: 2,
    down: 8,
    left: 4,
    right: 8,
    name: "Fujin and Raijin",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 57,
    up: 7,
    down: 3,
    left: 4,
    right: 8,
    name: "Elvoret",
    level: 6,
    rarity: 1,
    element: elements[3],
    edition: editions[0],
  },
  {
    id: 58,
    up: 4,
    down: 7,
    left: 3,
    right: 8,
    name: "X-ATM092",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 59,
    up: 7,
    down: 8,
    left: 5,
    right: 2,
    name: "Granaldo",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 60,
    up: 1,
    down: 8,
    left: 3,
    right: 8,
    name: "Gerogero",
    level: 6,
    rarity: 1,
    element: elements[4],
    edition: editions[0],
  },
  {
    id: 61,
    up: 8,
    down: 8,
    left: 2,
    right: 2,
    name: "Iguion",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 62,
    up: 6,
    down: 4,
    left: 5,
    right: 8,
    name: "Abadon",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 63,
    up: 4,
    down: 5,
    left: 6,
    right: 8,
    name: "Trauma",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 64,
    up: 1,
    down: 4,
    left: 8,
    right: 8,
    name: "Oilboyle",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 65,
    up: 6,
    down: 8,
    left: 4,
    right: 5,
    name: "Shumi Tribe",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 66,
    up: 7,
    down: 8,
    left: 1,
    right: 5,
    name: "Krysta",
    level: 6,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 67,
    up: 8,
    down: 4,
    left: 8,
    right: 4,
    name: "Propogator",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 68,
    up: 8,
    down: 4,
    left: 4,
    right: 8,
    name: "Jumbo Cactuar",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 69,
    up: 8,
    down: 2,
    left: 8,
    right: 5,
    name: "Tri-Point",
    level: 7,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 70,
    up: 5,
    down: 6,
    left: 8,
    right: 6,
    name: "Gargantua",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 71,
    up: 8,
    down: 7,
    left: 3,
    right: 6,
    name: "Mobile Type 8",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 72,
    up: 8,
    down: 5,
    left: 8,
    right: 3,
    name: "Sphinxara",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 73,
    up: 8,
    down: 5,
    left: 4,
    right: 8,
    name: "Tiamat",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 74,
    up: 5,
    down: 8,
    left: 5,
    right: 7,
    name: "BGH251F2",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 75,
    up: 6,
    down: 4,
    left: 7,
    right: 8,
    name: "Red Giant",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 76,
    up: 1,
    down: 7,
    left: 7,
    right: 8,
    name: "Catoblepas",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 77,
    up: 7,
    down: 2,
    left: 8,
    right: 7,
    name: "Ultima Weapon",
    level: 7,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 78,
    up: 4,
    down: 8,
    left: 9,
    right: 4,
    name: "Chubby Chocobo",
    level: 8,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 79,
    up: 9,
    down: 7,
    left: 3,
    right: 6,
    name: "Angelo",
    level: 8,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 80,
    up: 3,
    down: 9,
    left: 6,
    right: 7,
    name: "Gilgamesh",
    level: 8,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 81,
    up: 9,
    down: 9,
    left: 2,
    right: 3,
    name: "Mini Mog",
    level: 8,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 82,
    up: 9,
    down: 8,
    left: 4,
    right: 4,
    name: "Chicobo",
    level: 8,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 83,
    up: 2,
    down: 9,
    left: 4,
    right: 9,
    name: "Quezacotl",
    level: 8,
    rarity: 1,
    element: elements[1],
    edition: editions[0],
  },
  {
    id: 84,
    up: 6,
    down: 4,
    left: 9,
    right: 7,
    name: "Shiva",
    level: 8,
    rarity: 1,
    element: elements[2],
    edition: editions[0],
  },
  {
    id: 85,
    up: 9,
    down: 2,
    left: 8,
    right: 6,
    name: "Ifrit",
    level: 8,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 86,
    up: 8,
    down: 6,
    left: 2,
    right: 9,
    name: "Siren",
    level: 8,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 87,
    up: 5,
    down: 9,
    left: 9,
    right: 1,
    name: "Sacred",
    level: 8,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 88,
    up: 9,
    down: 2,
    left: 9,
    right: 5,
    name: "Minotaur",
    level: 8,
    rarity: 1,
    element: elements[7],
    edition: editions[0],
  },
  {
    id: 89,
    up: 8,
    down: 10,
    left: 4,
    right: 4,
    name: "Carbuncle",
    level: 9,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 90,
    up: 5,
    down: 8,
    left: 3,
    right: 10,
    name: "Diablos",
    level: 9,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 91,
    up: 7,
    down: 1,
    left: 7,
    right: 10,
    name: "Leviathan",
    level: 9,
    rarity: 1,
    element: elements[6],
    edition: editions[0],
  },
  {
    id: 92,
    up: 8,
    down: 3,
    left: 5,
    right: 10,
    name: "Odin",
    level: 9,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 93,
    up: 10,
    down: 7,
    left: 7,
    right: 1,
    name: "Pandemona",
    level: 9,
    rarity: 1,
    element: elements[3],
    edition: editions[0],
  },
  {
    id: 94,
    up: 7,
    down: 6,
    left: 10,
    right: 4,
    name: "Cerberus",
    level: 9,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 95,
    up: 9,
    down: 4,
    left: 2,
    right: 10,
    name: "Alexander",
    level: 9,
    rarity: 1,
    element: elements[8],
    edition: editions[0],
  },
  {
    id: 96,
    up: 7,
    down: 7,
    left: 10,
    right: 2,
    name: "Phoenix",
    level: 9,
    rarity: 1,
    element: elements[5],
    edition: editions[0],
  },
  {
    id: 97,
    up: 10,
    down: 2,
    left: 6,
    right: 8,
    name: "Bahamut",
    level: 9,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 98,
    up: 3,
    down: 10,
    left: 10,
    right: 1,
    name: "Doomtrain",
    level: 9,
    rarity: 1,
    element: elements[4],
    edition: editions[0],
  },
  {
    id: 99,
    up: 4,
    down: 9,
    left: 10,
    right: 4,
    name: "Eden",
    level: 9,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 100,
    up: 10,
    down: 2,
    left: 8,
    right: 7,
    name: "Ward",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 101,
    up: 6,
    down: 6,
    left: 10,
    right: 7,
    name: "Kiros",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 102,
    up: 5,
    down: 3,
    left: 9,
    right: 10,
    name: "Laguna",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 103,
    up: 10,
    down: 6,
    left: 4,
    right: 8,
    name: "Selphie",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 104,
    up: 9,
    down: 10,
    left: 2,
    right: 6,
    name: "Quistis",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 105,
    up: 2,
    down: 9,
    left: 10,
    right: 6,
    name: "Irvine",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 106,
    up: 8,
    down: 10,
    left: 6,
    right: 5,
    name: "Zell",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 107,
    up: 4,
    down: 2,
    left: 10,
    right: 10,
    name: "Rinoa",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 108,
    up: 10,
    down: 3,
    left: 3,
    right: 10,
    name: "Edea",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 109,
    up: 6,
    down: 10,
    left: 4,
    right: 9,
    name: "Seifer",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
  {
    id: 110,
    up: 10,
    down: 6,
    left: 9,
    right: 4,
    name: "Squall",
    level: 10,
    rarity: 1,
    element: elements[0],
    edition: editions[0],
  },
];
